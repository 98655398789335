'use client';

import setup from 'lib/http/setup';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { FelaProvider } from '../FelaProvider';

setup();

export function Providers({ children }: { children: React.ReactNode }) {
  const breakpoints = designSystemConfig.breakpoints.map((b) =>
    stripAllNonNumericChars(b),
  );
  return (
    <DeviceProvider breakpoints={breakpoints}>
      <ThemeProvider theme={designSystemConfig} themeClass="light">
        <FelaProvider>{children}</FelaProvider>
      </ThemeProvider>
    </DeviceProvider>
  );
}
